import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  numberAddition?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  numberAddition?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  street?: Maybe<Scalars['String']>;
};

export type CreateInstrumentInput = {
  amortisationDuration?: Maybe<Scalars['Int']>;
  currentValue?: Maybe<Scalars['Int']>;
  insurance?: Maybe<InstrumentInsurance>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['DateTime']>;
  purchasePrice?: Maybe<Scalars['Int']>;
  serialNumber?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateMemberInput = {
  addresses: Array<AddressInput>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  externalID?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gdprSignedAt?: Maybe<Scalars['DateTime']>;
  iban?: Maybe<Scalars['String']>;
  insertion?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  mobilePhone?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  yearlyContribution?: Maybe<Scalars['Int']>;
};

export type Instrument = {
  __typename?: 'Instrument';
  amortisationDuration?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  currentValue?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  insurance?: Maybe<InstrumentInsurance>;
  insuranceValue?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ownerExternal?: Maybe<Scalars['String']>;
  ownerMember?: Maybe<Member>;
  ownership: InstrumentOwnership;
  purchaseDate?: Maybe<Scalars['DateTime']>;
  purchasePrice?: Maybe<Scalars['Int']>;
  residualValue?: Maybe<Scalars['Int']>;
  serialNumber?: Maybe<Scalars['String']>;
  status: InstrumentUsage;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  usageMember?: Maybe<Member>;
};

export enum InstrumentInsurance {
  None = 'NONE',
  PurchaseValue = 'PURCHASE_VALUE',
  ResidualValue = 'RESIDUAL_VALUE'
}

export enum InstrumentOwnership {
  External = 'EXTERNAL',
  Member = 'MEMBER',
  Organisation = 'ORGANISATION'
}

export enum InstrumentUsage {
  GeneralUsage = 'GENERAL_USAGE',
  MemberUsage = 'MEMBER_USAGE',
  Stored = 'STORED'
}

export type Member = {
  __typename?: 'Member';
  addresses: Array<Address>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalID?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gdprSignedAt?: Maybe<Scalars['DateTime']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insertion?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  mobilePhone?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ownedInstruments: Array<Instrument>;
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  usedInstruments: Array<Instrument>;
  yearlyContribution?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveInstrument: Instrument;
  archiveMember: Member;
  createInstrument: Instrument;
  createMember: Member;
  deleteInstrument: SuccessResponse;
  deleteMember: SuccessResponse;
  restoreInstrument: Instrument;
  restoreMember: Member;
  setInstrumentOwner: Instrument;
  setInstrumentUsageStatus: Instrument;
  updateInstrument: Instrument;
  updateMember: Member;
};


export type MutationArchiveInstrumentArgs = {
  id: Scalars['ID'];
};


export type MutationArchiveMemberArgs = {
  id: Scalars['ID'];
};


export type MutationCreateInstrumentArgs = {
  input: CreateInstrumentInput;
};


export type MutationCreateMemberArgs = {
  input: CreateMemberInput;
};


export type MutationDeleteInstrumentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMemberArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreInstrumentArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreMemberArgs = {
  id: Scalars['ID'];
};


export type MutationSetInstrumentOwnerArgs = {
  input: SetInstrumentOwnerInput;
};


export type MutationSetInstrumentUsageStatusArgs = {
  input: SetInstrumentStatusInput;
};


export type MutationUpdateInstrumentArgs = {
  input: UpdateInstrumentInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};

export type PaginatedInstruments = {
  __typename?: 'PaginatedInstruments';
  items: Array<Instrument>;
  totalCount: Scalars['Int'];
};

export type PaginatedMembers = {
  __typename?: 'PaginatedMembers';
  items: Array<Member>;
  totalCount: Scalars['Int'];
};

export type PaginationSortInput = {
  amount?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sortKey?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<PaginationSortOrder>;
};

export enum PaginationSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PostcodeAddress = {
  __typename?: 'PostcodeAddress';
  city: Scalars['String'];
  houseNumber: Scalars['Int'];
  houseNumberAddition?: Maybe<Scalars['String']>;
  houseNumberAdditions: Array<Scalars['String']>;
  postcode: Scalars['String'];
  street: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  instrument: Instrument;
  instruments: PaginatedInstruments;
  member: Member;
  members: PaginatedMembers;
  resolvePostcode?: Maybe<PostcodeAddress>;
  tenant: Tenant;
  user: User;
  users: Array<User>;
  viewer: User;
};


export type QueryInstrumentArgs = {
  id: Scalars['ID'];
};


export type QueryInstrumentsArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<PaginationSortInput>;
};


export type QueryMemberArgs = {
  id: Scalars['ID'];
};


export type QueryMembersArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<PaginationSortInput>;
};


export type QueryResolvePostcodeArgs = {
  number: Scalars['Int'];
  postcode: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type SetInstrumentOwnerInput = {
  id: Scalars['ID'];
  ownerExternal?: Maybe<Scalars['String']>;
  ownerMemberId?: Maybe<Scalars['String']>;
  ownership: InstrumentOwnership;
};

export type SetInstrumentStatusInput = {
  id: Scalars['ID'];
  status: InstrumentUsage;
  usageMemberId?: Maybe<Scalars['String']>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type UpdateInstrumentInput = {
  amortisationDuration?: Maybe<Scalars['Int']>;
  currentValue?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  insurance?: Maybe<InstrumentInsurance>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['DateTime']>;
  purchasePrice?: Maybe<Scalars['Int']>;
  serialNumber?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UpdateMemberInput = {
  addresses: Array<AddressInput>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  externalID?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gdprSignedAt?: Maybe<Scalars['DateTime']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insertion?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  mobilePhone?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  yearlyContribution?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  lastLogin: Scalars['DateTime'];
  name: Scalars['String'];
  tenants: Array<Tenant>;
};

export type CreateMemberMutationVariables = Exact<{
  input: CreateMemberInput;
}>;


export type CreateMemberMutation = { __typename?: 'Mutation', createMember: { __typename?: 'Member', id: string, firstName: string, lastName: string, createdAt: any } };

export type UpdateMemberMutationVariables = Exact<{
  input: UpdateMemberInput;
}>;


export type UpdateMemberMutation = { __typename?: 'Mutation', updateMember: { __typename?: 'Member', id: string, firstName: string, lastName: string, createdAt: any } };

export type DeleteMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMemberMutation = { __typename?: 'Mutation', deleteMember: { __typename?: 'SuccessResponse', success: boolean } };

export type CreateInstrumentMutationVariables = Exact<{
  input: CreateInstrumentInput;
}>;


export type CreateInstrumentMutation = { __typename?: 'Mutation', createInstrument: { __typename?: 'Instrument', id: string } };

export type UpdateInstrumentMutationVariables = Exact<{
  input: UpdateInstrumentInput;
}>;


export type UpdateInstrumentMutation = { __typename?: 'Mutation', updateInstrument: { __typename?: 'Instrument', id: string } };

export type DeleteInstrumentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteInstrumentMutation = { __typename?: 'Mutation', deleteInstrument: { __typename?: 'SuccessResponse', success: boolean } };

export type SetInstrumentOwnerMutationVariables = Exact<{
  input: SetInstrumentOwnerInput;
}>;


export type SetInstrumentOwnerMutation = { __typename?: 'Mutation', setInstrumentOwner: { __typename?: 'Instrument', id: string } };

export type SetInstrumentUsageStatusMutationVariables = Exact<{
  input: SetInstrumentStatusInput;
}>;


export type SetInstrumentUsageStatusMutation = { __typename?: 'Mutation', setInstrumentUsageStatus: { __typename?: 'Instrument', id: string } };

export type GetMembersQueryVariables = Exact<{
  pagination?: Maybe<PaginationSortInput>;
}>;


export type GetMembersQuery = { __typename?: 'Query', members: { __typename?: 'PaginatedMembers', items: Array<{ __typename?: 'Member', id: string, firstName: string, insertion?: string | null | undefined, lastName: string, email?: string | null | undefined, phone?: string | null | undefined, mobilePhone?: string | null | undefined, iban?: string | null | undefined, gdprSignedAt?: any | null | undefined, dateOfBirth?: string | null | undefined, notes?: string | null | undefined, externalID?: string | null | undefined, yearlyContribution?: number | null | undefined, createdAt: any, updatedAt: any, addresses: Array<{ __typename?: 'Address', street?: string | null | undefined, city?: string | null | undefined, postcode: string, number: number, numberAddition?: string | null | undefined }> }> } };

export type GetMemberByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMemberByIdQuery = { __typename?: 'Query', member: { __typename?: 'Member', id: string, firstName: string, insertion?: string | null | undefined, lastName: string, email?: string | null | undefined, phone?: string | null | undefined, mobilePhone?: string | null | undefined, iban?: string | null | undefined, gdprSignedAt?: any | null | undefined, dateOfBirth?: string | null | undefined, notes?: string | null | undefined, externalID?: string | null | undefined, yearlyContribution?: number | null | undefined, createdAt: any, updatedAt: any, addresses: Array<{ __typename?: 'Address', street?: string | null | undefined, city?: string | null | undefined, postcode: string, number: number, numberAddition?: string | null | undefined }> } };

export type GetInstrumentsQueryVariables = Exact<{
  pagination?: Maybe<PaginationSortInput>;
}>;


export type GetInstrumentsQuery = { __typename?: 'Query', instruments: { __typename?: 'PaginatedInstruments', items: Array<{ __typename?: 'Instrument', id: string, type: string, currentValue?: number | null | undefined, manufacturer?: string | null | undefined, model?: string | null | undefined, purchaseDate?: any | null | undefined, purchasePrice?: number | null | undefined, serialNumber?: string | null | undefined, createdAt: any, updatedAt: any, status: InstrumentUsage, notes?: string | null | undefined, ownership: InstrumentOwnership, ownerExternal?: string | null | undefined, ownerMember?: { __typename?: 'Member', id: string, firstName: string, insertion?: string | null | undefined, lastName: string } | null | undefined, usageMember?: { __typename?: 'Member', id: string, firstName: string, insertion?: string | null | undefined, lastName: string } | null | undefined }> } };

export type GetInstrumentByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInstrumentByIdQuery = { __typename?: 'Query', instrument: { __typename?: 'Instrument', id: string, type: string, currentValue?: number | null | undefined, manufacturer?: string | null | undefined, model?: string | null | undefined, purchaseDate?: any | null | undefined, purchasePrice?: number | null | undefined, serialNumber?: string | null | undefined, createdAt: any, updatedAt: any, status: InstrumentUsage, notes?: string | null | undefined, ownership: InstrumentOwnership, ownerExternal?: string | null | undefined, ownerMember?: { __typename?: 'Member', id: string, firstName: string, insertion?: string | null | undefined, lastName: string } | null | undefined } };

export type GetTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantQuery = { __typename?: 'Query', tenant: { __typename?: 'Tenant', id: string, name: string, slug: string } };

export type ResolveAddressQueryVariables = Exact<{
  postcode: Scalars['String'];
  number: Scalars['Int'];
}>;


export type ResolveAddressQuery = { __typename?: 'Query', resolvePostcode?: { __typename?: 'PostcodeAddress', street: string, houseNumber: number, houseNumberAddition?: string | null | undefined, postcode: string, city: string, houseNumberAdditions: Array<string> } | null | undefined };


export const CreateMemberDocument = gql`
    mutation CreateMember($input: CreateMemberInput!) {
  createMember(input: $input) {
    id
    firstName
    lastName
    createdAt
  }
}
    `;
export type CreateMemberMutationFn = Apollo.MutationFunction<CreateMemberMutation, CreateMemberMutationVariables>;

/**
 * __useCreateMemberMutation__
 *
 * To run a mutation, you first call `useCreateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberMutation, { data, loading, error }] = useCreateMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberMutation, CreateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberMutation, CreateMemberMutationVariables>(CreateMemberDocument, options);
      }
export type CreateMemberMutationHookResult = ReturnType<typeof useCreateMemberMutation>;
export type CreateMemberMutationResult = Apollo.MutationResult<CreateMemberMutation>;
export type CreateMemberMutationOptions = Apollo.BaseMutationOptions<CreateMemberMutation, CreateMemberMutationVariables>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($input: UpdateMemberInput!) {
  updateMember(input: $input) {
    id
    firstName
    lastName
    createdAt
  }
}
    `;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, options);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const DeleteMemberDocument = gql`
    mutation DeleteMember($id: ID!) {
  deleteMember(id: $id) {
    success
  }
}
    `;
export type DeleteMemberMutationFn = Apollo.MutationFunction<DeleteMemberMutation, DeleteMemberMutationVariables>;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberMutation, DeleteMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, options);
      }
export type DeleteMemberMutationHookResult = ReturnType<typeof useDeleteMemberMutation>;
export type DeleteMemberMutationResult = Apollo.MutationResult<DeleteMemberMutation>;
export type DeleteMemberMutationOptions = Apollo.BaseMutationOptions<DeleteMemberMutation, DeleteMemberMutationVariables>;
export const CreateInstrumentDocument = gql`
    mutation CreateInstrument($input: CreateInstrumentInput!) {
  createInstrument(input: $input) {
    id
  }
}
    `;
export type CreateInstrumentMutationFn = Apollo.MutationFunction<CreateInstrumentMutation, CreateInstrumentMutationVariables>;

/**
 * __useCreateInstrumentMutation__
 *
 * To run a mutation, you first call `useCreateInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstrumentMutation, { data, loading, error }] = useCreateInstrumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstrumentMutation, CreateInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstrumentMutation, CreateInstrumentMutationVariables>(CreateInstrumentDocument, options);
      }
export type CreateInstrumentMutationHookResult = ReturnType<typeof useCreateInstrumentMutation>;
export type CreateInstrumentMutationResult = Apollo.MutationResult<CreateInstrumentMutation>;
export type CreateInstrumentMutationOptions = Apollo.BaseMutationOptions<CreateInstrumentMutation, CreateInstrumentMutationVariables>;
export const UpdateInstrumentDocument = gql`
    mutation UpdateInstrument($input: UpdateInstrumentInput!) {
  updateInstrument(input: $input) {
    id
  }
}
    `;
export type UpdateInstrumentMutationFn = Apollo.MutationFunction<UpdateInstrumentMutation, UpdateInstrumentMutationVariables>;

/**
 * __useUpdateInstrumentMutation__
 *
 * To run a mutation, you first call `useUpdateInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstrumentMutation, { data, loading, error }] = useUpdateInstrumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstrumentMutation, UpdateInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstrumentMutation, UpdateInstrumentMutationVariables>(UpdateInstrumentDocument, options);
      }
export type UpdateInstrumentMutationHookResult = ReturnType<typeof useUpdateInstrumentMutation>;
export type UpdateInstrumentMutationResult = Apollo.MutationResult<UpdateInstrumentMutation>;
export type UpdateInstrumentMutationOptions = Apollo.BaseMutationOptions<UpdateInstrumentMutation, UpdateInstrumentMutationVariables>;
export const DeleteInstrumentDocument = gql`
    mutation DeleteInstrument($id: ID!) {
  deleteInstrument(id: $id) {
    success
  }
}
    `;
export type DeleteInstrumentMutationFn = Apollo.MutationFunction<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>;

/**
 * __useDeleteInstrumentMutation__
 *
 * To run a mutation, you first call `useDeleteInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstrumentMutation, { data, loading, error }] = useDeleteInstrumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>(DeleteInstrumentDocument, options);
      }
export type DeleteInstrumentMutationHookResult = ReturnType<typeof useDeleteInstrumentMutation>;
export type DeleteInstrumentMutationResult = Apollo.MutationResult<DeleteInstrumentMutation>;
export type DeleteInstrumentMutationOptions = Apollo.BaseMutationOptions<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>;
export const SetInstrumentOwnerDocument = gql`
    mutation SetInstrumentOwner($input: SetInstrumentOwnerInput!) {
  setInstrumentOwner(input: $input) {
    id
  }
}
    `;
export type SetInstrumentOwnerMutationFn = Apollo.MutationFunction<SetInstrumentOwnerMutation, SetInstrumentOwnerMutationVariables>;

/**
 * __useSetInstrumentOwnerMutation__
 *
 * To run a mutation, you first call `useSetInstrumentOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInstrumentOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInstrumentOwnerMutation, { data, loading, error }] = useSetInstrumentOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetInstrumentOwnerMutation(baseOptions?: Apollo.MutationHookOptions<SetInstrumentOwnerMutation, SetInstrumentOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetInstrumentOwnerMutation, SetInstrumentOwnerMutationVariables>(SetInstrumentOwnerDocument, options);
      }
export type SetInstrumentOwnerMutationHookResult = ReturnType<typeof useSetInstrumentOwnerMutation>;
export type SetInstrumentOwnerMutationResult = Apollo.MutationResult<SetInstrumentOwnerMutation>;
export type SetInstrumentOwnerMutationOptions = Apollo.BaseMutationOptions<SetInstrumentOwnerMutation, SetInstrumentOwnerMutationVariables>;
export const SetInstrumentUsageStatusDocument = gql`
    mutation SetInstrumentUsageStatus($input: SetInstrumentStatusInput!) {
  setInstrumentUsageStatus(input: $input) {
    id
  }
}
    `;
export type SetInstrumentUsageStatusMutationFn = Apollo.MutationFunction<SetInstrumentUsageStatusMutation, SetInstrumentUsageStatusMutationVariables>;

/**
 * __useSetInstrumentUsageStatusMutation__
 *
 * To run a mutation, you first call `useSetInstrumentUsageStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInstrumentUsageStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInstrumentUsageStatusMutation, { data, loading, error }] = useSetInstrumentUsageStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetInstrumentUsageStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetInstrumentUsageStatusMutation, SetInstrumentUsageStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetInstrumentUsageStatusMutation, SetInstrumentUsageStatusMutationVariables>(SetInstrumentUsageStatusDocument, options);
      }
export type SetInstrumentUsageStatusMutationHookResult = ReturnType<typeof useSetInstrumentUsageStatusMutation>;
export type SetInstrumentUsageStatusMutationResult = Apollo.MutationResult<SetInstrumentUsageStatusMutation>;
export type SetInstrumentUsageStatusMutationOptions = Apollo.BaseMutationOptions<SetInstrumentUsageStatusMutation, SetInstrumentUsageStatusMutationVariables>;
export const GetMembersDocument = gql`
    query getMembers($pagination: PaginationSortInput) {
  members(pagination: $pagination) {
    items {
      id
      firstName
      insertion
      lastName
      email
      phone
      mobilePhone
      iban
      gdprSignedAt
      dateOfBirth
      notes
      externalID
      yearlyContribution
      addresses {
        street
        city
        postcode
        number
        numberAddition
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
      }
export function useGetMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
        }
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const GetMemberByIdDocument = gql`
    query getMemberById($id: ID!) {
  member(id: $id) {
    id
    firstName
    insertion
    lastName
    email
    phone
    mobilePhone
    iban
    gdprSignedAt
    dateOfBirth
    notes
    externalID
    yearlyContribution
    addresses {
      street
      city
      postcode
      number
      numberAddition
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMemberByIdQuery, GetMemberByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberByIdQuery, GetMemberByIdQueryVariables>(GetMemberByIdDocument, options);
      }
export function useGetMemberByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberByIdQuery, GetMemberByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberByIdQuery, GetMemberByIdQueryVariables>(GetMemberByIdDocument, options);
        }
export type GetMemberByIdQueryHookResult = ReturnType<typeof useGetMemberByIdQuery>;
export type GetMemberByIdLazyQueryHookResult = ReturnType<typeof useGetMemberByIdLazyQuery>;
export type GetMemberByIdQueryResult = Apollo.QueryResult<GetMemberByIdQuery, GetMemberByIdQueryVariables>;
export const GetInstrumentsDocument = gql`
    query getInstruments($pagination: PaginationSortInput) {
  instruments(pagination: $pagination) {
    items {
      id
      type
      currentValue
      manufacturer
      model
      purchaseDate
      purchasePrice
      serialNumber
      createdAt
      updatedAt
      status
      notes
      ownership
      ownerExternal
      ownerMember {
        id
        firstName
        insertion
        lastName
      }
      status
      usageMember {
        id
        firstName
        insertion
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetInstrumentsQuery__
 *
 * To run a query within a React component, call `useGetInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstrumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstrumentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetInstrumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetInstrumentsQuery, GetInstrumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstrumentsQuery, GetInstrumentsQueryVariables>(GetInstrumentsDocument, options);
      }
export function useGetInstrumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstrumentsQuery, GetInstrumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstrumentsQuery, GetInstrumentsQueryVariables>(GetInstrumentsDocument, options);
        }
export type GetInstrumentsQueryHookResult = ReturnType<typeof useGetInstrumentsQuery>;
export type GetInstrumentsLazyQueryHookResult = ReturnType<typeof useGetInstrumentsLazyQuery>;
export type GetInstrumentsQueryResult = Apollo.QueryResult<GetInstrumentsQuery, GetInstrumentsQueryVariables>;
export const GetInstrumentByIdDocument = gql`
    query getInstrumentById($id: ID!) {
  instrument(id: $id) {
    id
    type
    currentValue
    manufacturer
    model
    purchaseDate
    purchasePrice
    serialNumber
    createdAt
    updatedAt
    status
    notes
    ownership
    ownerExternal
    ownerMember {
      id
      firstName
      insertion
      lastName
    }
  }
}
    `;

/**
 * __useGetInstrumentByIdQuery__
 *
 * To run a query within a React component, call `useGetInstrumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstrumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstrumentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstrumentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetInstrumentByIdQuery, GetInstrumentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstrumentByIdQuery, GetInstrumentByIdQueryVariables>(GetInstrumentByIdDocument, options);
      }
export function useGetInstrumentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstrumentByIdQuery, GetInstrumentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstrumentByIdQuery, GetInstrumentByIdQueryVariables>(GetInstrumentByIdDocument, options);
        }
export type GetInstrumentByIdQueryHookResult = ReturnType<typeof useGetInstrumentByIdQuery>;
export type GetInstrumentByIdLazyQueryHookResult = ReturnType<typeof useGetInstrumentByIdLazyQuery>;
export type GetInstrumentByIdQueryResult = Apollo.QueryResult<GetInstrumentByIdQuery, GetInstrumentByIdQueryVariables>;
export const GetTenantDocument = gql`
    query getTenant {
  tenant {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantQuery, GetTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
      }
export function useGetTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantQuery, GetTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
        }
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>;
export type GetTenantLazyQueryHookResult = ReturnType<typeof useGetTenantLazyQuery>;
export type GetTenantQueryResult = Apollo.QueryResult<GetTenantQuery, GetTenantQueryVariables>;
export const ResolveAddressDocument = gql`
    query ResolveAddress($postcode: String!, $number: Int!) {
  resolvePostcode(postcode: $postcode, number: $number) {
    street
    houseNumber
    houseNumberAddition
    postcode
    city
    houseNumberAdditions
  }
}
    `;

/**
 * __useResolveAddressQuery__
 *
 * To run a query within a React component, call `useResolveAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolveAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolveAddressQuery({
 *   variables: {
 *      postcode: // value for 'postcode'
 *      number: // value for 'number'
 *   },
 * });
 */
export function useResolveAddressQuery(baseOptions: Apollo.QueryHookOptions<ResolveAddressQuery, ResolveAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResolveAddressQuery, ResolveAddressQueryVariables>(ResolveAddressDocument, options);
      }
export function useResolveAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResolveAddressQuery, ResolveAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResolveAddressQuery, ResolveAddressQueryVariables>(ResolveAddressDocument, options);
        }
export type ResolveAddressQueryHookResult = ReturnType<typeof useResolveAddressQuery>;
export type ResolveAddressLazyQueryHookResult = ReturnType<typeof useResolveAddressLazyQuery>;
export type ResolveAddressQueryResult = Apollo.QueryResult<ResolveAddressQuery, ResolveAddressQueryVariables>;