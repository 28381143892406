import { ReactElement, useRef } from 'react';
import { StyleClass } from 'primereact/styleclass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface INavigationItem {
  path: string;
  title: string;
  active: boolean;
  icon: IconProp;
}

interface Props {
  logoUrl: string;
  userName: string;
  onLogout(): void;
  navigationItems: INavigationItem[];
  onNavigationItemClick(option: INavigationItem): void;
}

export function Sidebar({ logoUrl, navigationItems, onNavigationItemClick, userName, onLogout }: Props): ReactElement {
  const btnRef3 = useRef<HTMLDivElement>(null);
  return (
    <div
      id="app-sidebar-1"
      className="bg-bluegray-800 h-screen hidden xl:block flex-shrink-0 absolute xl:static left-0 top-0 z-1 select-none"
      style={{ width: '280px' }}
    >
      <div className="flex flex-column h-full">
        <div
          className="flex align-items-center px-5 bg-bluegray-900 flex-shrink-0 text-white"
          style={{ height: '60px' }}
        >
          <img src={logoUrl} height={30} alt="Strumento logo" />
          <span className="ml-2 text-xl">Strumento</span>
        </div>
        <div className="overflow-y-auto mt-3">
          <ul className="list-none p-3 m-0">
            {navigationItems.map((navItem) => (
              <li key={navItem.path}>
                <div
                  role="button"
                  onClick={() => onNavigationItemClick(navItem)}
                  className={`flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full ${navItem.active && 'bg-bluegray-900'}`}
                >
                  <FontAwesomeIcon icon={navItem.icon} width={18} />
                  <span className="font-medium ml-2">{navItem.title}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-auto mx-3">
          <hr className="mb-3 border-top-1 border-bluegray-600" />
          <ul className="list-none p-2 m-0 hidden">
            <li>
              <div
                role="button"
                onClick={onLogout}
                className="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
            transition-duration-150 transition-colors w-full"
              >
                <i className="pi pi-sign-out mr-2"></i>
                <span className="font-medium">Uitloggen</span>
              </div>
            </li>
          </ul>
          <StyleClass
            nodeRef={btnRef3}
            selector="@prev"
            enterClassName="hidden"
            enterActiveClassName="fadein"
            leaveToClassName="hidden"
            leaveActiveClassName="fadeout"
          >
            <div
              role="button"
              ref={btnRef3}
              className="my-3 px-3 py-2 flex align-items-center hover:bg-bluegray-900 border-round cursor-pointer text-bluegray-100 hover:text-bluegray-50
        transition-duration-150 transition-colors w-full"
            >
              <FontAwesomeIcon icon={faBuilding} width={18} className="mr-2" />
              <span className="font-medium">{userName}</span>
              <i className={`pi pi-chevron-${btnRef3.current?.className.includes('')} ml-auto`}></i>
            </div>
          </StyleClass>
        </div>
      </div>
    </div>
  );
}
