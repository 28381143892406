import { ReactElement } from 'react';
import { Field, useFormikContext } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import { get } from 'lodash';

interface Props {
  name: string;
  id?: string;
}

export function InputCurrency({ name, id }: Props): ReactElement {
  const { setFieldValue, values } = useFormikContext();

  return (
    <Field
      as={InputNumber}
      mode="currency"
      currency="EUR"
      locale="nl-NL"
      value={get(values, name) / 100}
      onChange={(e: { value: number }) => setFieldValue(name, e.value * 100)}
      id={id}
    />
  );
}
