import React, { ReactElement } from 'react';
import { Calendar, CalendarProps } from 'primereact/calendar';
import { Field } from 'formik';
import { addLocale } from 'primereact/api';

addLocale('nl', {
  firstDayOfWeek: 1,
  dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
  dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
  dayNamesMin: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
  monthNames: [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december',
  ],
  monthNamesShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
  today: 'Vandaag',
  clear: 'Wissen',
});

export function InputDate(props: CalendarProps): ReactElement {
  return <Field as={Calendar} locale="nl" mask="99-99-9999" {...props} />;
}
